String.prototype.localize = function (data = {}) {
    const replacers = {
        '_': '&nbsp;',
        ...data
    };

    return this
        .replace(/~([^<>]+)~/g, function (keyExpr, key) {
            // ~text~ -> <span (small)>text</span>
            return `<span class="text-black/40 text-sm inline-flex">${key}</span>`
        })
        .replace(/\[\[([^\[\[\]\]]+)]]/g, function (keyExpr, key) {
            // [[text]] -> <i>text</i>
            return `<i>${key}</i>`
        })
        .replace(/\[([^\[\]]+)]/g, function (keyExpr, key) {
            // [text] -> <b>text</b>
            return `<b>${key}</b>`
        })
        .replace(/{([^{}]+)}/g, function (keyExpr, key) {
            // {emoji} -> replacers['emoji']
            // {_} -> &nbsp;
            return replacers[key] || '';
        });
}