import cs from './cs/strings.json';
import en from './en/strings.json';
import es from './es/strings.json';
import ro from './ro/strings.json';
import pl from './pl/strings.json';

export default {
    cs,
    en,
    es,
    ro,
    pl
}
