import '../styles/globals.css'
import Head from "next/head";
import {generateTitle} from "../helpers/meta";
import '../helpers/strings';
import {useRouter} from "next/router";
import localized from "../locale/locales";
import {Fragment} from "react";

const baseUrl = 'https://www.worktravel.jobs';

function MyApp({Component, pageProps}) {
    const router = useRouter();
    const {locale, locales, defaultLocale, asPath} = router;
    const {og: lang} = localized[locale];

    const ogUrl = (locale === defaultLocale ? `${baseUrl}${asPath}` : `${baseUrl}${locale}${asPath}`).split('?')[0];

    return (
        <>
            <Head>
                <title key="title">{generateTitle()}</title>
                <meta key="og:title" property="og:title" content={generateTitle()}/>
                <meta key="description" name="description" content={lang.description}/>
                <meta key="og:description" property="og:description" content={lang.description}/>
                <meta property="og:url" content={ogUrl}/>

                <link rel="canonical" href={ogUrl}/>

                {locales.map((lang) => {
                    const path = asPath === '/' ? '' : asPath;
                    const langPath = lang === 'cs' ? '' : `/${lang}`;
                    const url = `${baseUrl}${langPath}${path}`;

                    return (
                        <Fragment key={`Link: ${lang}`}>
                            {lang === defaultLocale && (
                                <link rel="alternate" href={url} hrefLang="x-default"/>
                            )}
                            <link rel="alternate" href={url} hrefLang={lang}/>
                        </Fragment>
                    )
                })}
            </Head>
            <Component {...pageProps} />
        </>
    )
}

export default MyApp
